const postComments = document.querySelector( '.post__comments' );
if ( postComments != null ) {
  const commentList = postComments.querySelector( '.comment__list' );
  const replyButtons = commentList.querySelectorAll( '.comment__reply' );
  const replyForms = commentList.querySelectorAll( '.comment__reply-form' );

  const isExpanded = x => x.getAttribute( 'aria-expanded' ) === 'true';
  const setExpanded = ( x, value ) => x.setAttribute( 'aria-expanded', value );
  const setHidden = ( x, value ) => x.setAttribute( 'aria-hidden', value );

  document.addEventListener( 'DOMContentLoaded', event => {
    postComments.classList.remove( 'no-js' );

    for ( let i = 0; i < replyForms.length; i++ ) {
      replyForms[ i ].setAttribute( 'aria-hidden', true );
    }
  } );

  for ( let i = 0; i < replyButtons.length; i++ ) {
    replyButtons[ i ].addEventListener( 'click', function ( e ) {

      if ( !isExpanded( replyButtons[ i ] ) ) {
        setExpanded( replyButtons[ i ], 'true' );
        replyButtons[ i ].innerHTML = 'Close reply form';
        replyButtons[ i ].classList.add( 'comment__reply-close' );
        setHidden( replyForms[ i ], 'false' );
      } else {
        setExpanded( replyButtons[ i ], 'false' );
        replyButtons[ i ].innerHTML = replyForms[ i ].querySelector( 'h3' ).innerHTML;
        replyButtons[ i ].classList.remove( 'comment__reply-close' );
        setHidden( replyForms[ i ], 'true' );
      }
    }, false );
  }
}
